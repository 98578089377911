$separator-color-light: #313131;
$separator-color: #424242;
$background-color: #1a1b18;
$foreground-color: #212220;
$input-background: #232223;

$dark-btn-background: #8d8d8d;
$light-btn-background: #e4e4e4;
$button-text-color: #d0d0d0;

$theme-color-1: #63883b;
$theme-color-2: #4d7058;
$theme-color-3: #9ca397;
$theme-color-4: #808d6e;
$theme-color-5: #5d946f;
$theme-color-6: #7e9172;

$primary-color: #8f8f8f;
$secondary-color: #707070;
$muted-color: #696969;

$gradient-color-1 : #6b8f45;
$gradient-color-2 : #567535;
$gradient-color-3 : #5f803c;

$lp-bg-color-1 : #181f0d;
$lp-bg-color-2 : #1e2b0b;
$lp-bg-color-3 : #2f4311;
$lp-bg-color-4 : #3c5814;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 2 6 10 14 19;
$shadowOpacitiesTop: 0.1 0.3 0.6 0.7 0.8;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.1 0.3 0.6 0.7 0.8;

$logoPath: "../../../assets/img/eTrappLogoDesignFINAL.svg";
$logoPathMobile: "../../../assets/img/eTrappLogoDesignFINAL.svg";

$lpLogoPath: "../../../assets/img/eTrappLogoDesignFINAL.svg";
$lpLogoPathPinned: "../../../assets/img/eTrappLogoDesignFINAL.svg";

@import "../_mixins.scss";
@import "../_vien.style.scss";
